body {
    background-image: url('../images/bg-auth.png');
    background-size: auto;
}
.ml-2 {
    margin-left: 20px !important;
}
.mt-5 {
    margin-top: 35px !important;
}
.auth-box {
    border: 1px solid rgb(255 255 255 / 23%);
    padding: 15px;
    background-color: rgb(255 255 255 / 23%);
}
.text-right {
    text-align: right !important;
}
.dc-count {
    font: normal normal 50px/76px "Roboto", "Helvetica", "Arial", sans-serif !important;
}
a {
    text-decoration: none !important;
}
.cursor-pointer {
    cursor: pointer;
}
.link {
    color: #52ABE8 !important;
}
.verified {
    color: #2ecc71;
}
.un-verified {
    color: #e74c3c;
}
.verified {
    color: #2ecc71;
}
.un-verified {
    color: #e74c3c;
}
